// src/components/Header.js
import React from "react";

const Header = ({ toggleSidebar }) => {
  return (
    <header className="bg-black text-white flex items-center justify-between p-4 shadow-lg">
      <div className="flex items-center">
        <button
          onClick={toggleSidebar}
          className="text-red-500 hover:text-red-700 focus:outline-none flex-shrink-0 mr-2 md:mr-4"
        >
          <svg
            className="h-6 w-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            ></path>
          </svg>
        </button>
        <div className="text-xl md:text-2xl font-bold ml-4 truncate">
          Dashboard
        </div>
      </div>
      <div className="flex items-center">
        <div className="relative">
          <img
            className="h-8 w-8 md:h-10 md:w-10 rounded-full object-cover"
            src="https://via.placeholder.com/150"
            alt="User Avatar"
          />
          <span className="absolute bottom-0 right-0 block h-2 w-2 bg-green-500 rounded-full ring-2 ring-white"></span>
        </div>
      </div>
    </header>
  );
};

export default Header;
