// src/components/Dashboard.js
import React, { useState } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { useNavigate, Outlet } from "react-router-dom";

const Dashboard = () => {
  const [iframeUrl, setIframeUrl] = useState("");
  const navigate = useNavigate();
  return (
    <div className="flex h-screen overflow-hidden bg-gray-100">
      <Sidebar />
      <div className="flex-1 flex flex-col">
        <Header />

        {/* Mantén el Outlet aquí para renderizar rutas hijas */}
        <main className="flex-1 overflow-y-auto p-4 bg-white">
          <Outlet />

          {/* Tu contenido específico del Dashboard */}
          {/* <div className="p-6 bg-gray-400 rounded-lg shadow-lg text-white">
            <h2 className="text-xl mb-4">Bienvenidos a GacIngs</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
          </div> */}

          <div className="flex justify-end mb-4 mt-4">
            <select
              onChange={(e) => setIframeUrl(e.target.value)}
              className="p-2 border border-gray-400 rounded"
            >
              <option value="">Selecciona un nodo</option>
              <option value="https://app.powerbi.com/view?r=eyJrIjoiMWI5ZmMxZjItMDViMy00ODM5LTg0YTYtOGFjMDIwNWQ2ZjU0IiwidCI6Ijg2OTc4ZjJjLThkOTgtNDkwMS1iY2Y5LTEzZTM4OWQ1NTVmOSIsImMiOjR9">
                Power BI 1
              </option>
              <option value="https://app.powerbi.com/view?r=eyJrIjoiODdkODkyMTItMzFhMC00NDgwLTkxMjQtZGMzNzhjYjg0MzA1IiwidCI6Ijg2OTc4ZjJjLThkOTgtNDkwMS1iY2Y5LTEzZTM4OWQ1NTVmOSIsImMiOjR9">
                Power BI 2
              </option>
            </select>
          </div>
          {iframeUrl && (
            <iframe
              src={iframeUrl}
              title="Power BI"
              className="w-full h-full border-none"
              style={{ minHeight: "calc(100vh - 72px)" }}
            />
          )}
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
