import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Chart from "react-apexcharts";

const Graficos = () => {
  const [selectedOption, setSelectedOption] = useState("Voltaje");
  const [options, setOptions] = useState({
    chart: {
      type: "line",
      zoom: {
        enabled: true,
        type: "x",
        autoScaleYaxis: true,
      },
      toolbar: {
        show: true, // Mantener la barra de herramientas visible con íconos
      },
      // Añadimos traducción al español mediante locales
      locales: [
        {
          name: "es",
          options: {
            toolbar: {
              exportToSVG: "Descargar SVG",
              exportToPNG: "Descargar PNG",
              exportToCSV: "Descargar CSV",
              selection: "Seleccionar",
              selectionZoom: "Seleccionar Zoom",
              zoomIn: "Acercar",
              zoomOut: "Alejar",
              pan: "Desplazar",
              reset: "Restablecer Zoom",
            },
          },
        },
      ],
      defaultLocale: "es", // Definir español como el idioma predeterminado
      // toolbar: {
      //   tools: {
      //     download: "Descargar SVG",
      //     selection: "Seleccionar",
      //     zoom: "Zoom",
      //     zoomin: "Acercar",
      //     zoomout: "Alejar",
      //     pan: "Desplazar",
      //     reset: "Reiniciar Zoom",
      //   },
      // },
    },
    xaxis: {
      type: "datetime",
      labels: {
        datetimeFormatter: {
          year: "yyyy",
          month: "MMM 'yy", // 'MMM' muestra los 3 primeros caracteres del mes
          day: "dd MMM",
          hour: "HH:mm",
        },
        formatter: function (value) {
          const date = new Date(value);
          return date.toLocaleDateString("es-ES", {
            year: "numeric",
            month: "short", // Muestra el mes abreviado
            day: "numeric",
          });
        },
      },
    },
    tooltip: {
      x: {
        format: "yyyy-MM-dd HH:mm",
      },
    },
    stroke: {
      curve: "smooth",
    },
    colors: ["#FF4560", "#00E396", "#008FFB", "#FEB019"],
    dataLabels: {
      enabled: false,
    },
  });

  const [series, setSeries] = useState([]);

  useEffect(() => {
    fetch("/mock.json")
      .then((response) => response.json())
      .then((data) => {
        const augustData = data.feeds.filter((item) => {
          const date = new Date(item.created_at);
          return date.getFullYear() === 2024 && date.getMonth() === 7;
        });

        const fieldData = {
          Voltaje: [
            {
              name: "Voltaje (Field 1)",
              data: augustData.map((item) => ({
                x: item.created_at,
                y: parseFloat(item.field1),
              })),
            },
          ],
          "Temperatura y HR": [
            {
              name: "Temperatura (Field 2)",
              data: augustData.map((item) => ({
                x: item.created_at,
                y: parseFloat(item.field2),
              })),
            },
            {
              name: "HR (Field 3)",
              data: augustData.map((item) => ({
                x: item.created_at,
                y: parseFloat(item.field3),
              })),
            },
          ],
          "Humedad del suelo": [
            {
              name: "Humedad del Suelo 1 (Field 4)",
              data: augustData.map((item) => ({
                x: item.created_at,
                y: parseFloat(item.field4),
              })),
            },
            {
              name: "Humedad del Suelo 2 (Field 6)",
              data: augustData.map((item) => ({
                x: item.created_at,
                y: parseFloat(item.field6),
              })),
            },
          ],
          "Temperatura del suelo": [
            {
              name: "Temperatura del Suelo 1 (Field 5)",
              data: augustData.map((item) => ({
                x: item.created_at,
                y: parseFloat(item.field5),
              })),
            },
            {
              name: "Temperatura del Suelo 2 (Field 7)",
              data: augustData.map((item) => ({
                x: item.created_at,
                y: parseFloat(item.field7),
              })),
            },
          ],
          Todo: [
            {
              name: "Voltaje (Field 1)",
              data: augustData.map((item) => ({
                x: item.created_at,
                y: parseFloat(item.field1),
              })),
            },
            {
              name: "Temperatura (Field 2)",
              data: augustData.map((item) => ({
                x: item.created_at,
                y: parseFloat(item.field2),
              })),
            },
            {
              name: "HR (Field 3)",
              data: augustData.map((item) => ({
                x: item.created_at,
                y: parseFloat(item.field3),
              })),
            },
            {
              name: "Humedad del Suelo 1 (Field 4)",
              data: augustData.map((item) => ({
                x: item.created_at,
                y: parseFloat(item.field4),
              })),
            },
            {
              name: "Temperatura del Suelo 1 (Field 5)",
              data: augustData.map((item) => ({
                x: item.created_at,
                y: parseFloat(item.field5),
              })),
            },
            {
              name: "Humedad del Suelo 2 (Field 6)",
              data: augustData.map((item) => ({
                x: item.created_at,
                y: parseFloat(item.field6),
              })),
            },
            {
              name: "Temperatura del Suelo 2 (Field 7)",
              data: augustData.map((item) => ({
                x: item.created_at,
                y: parseFloat(item.field7),
              })),
            },
          ],
        };

        setSeries(fieldData[selectedOption]);
      })
      .catch((error) => {
        console.error("Error al cargar los datos:", error);
      });
  }, [selectedOption]);

  return (
    <div className="flex h-screen overflow-hidden bg-gray-100">
      <Sidebar />
      <div className="flex-1 flex flex-col">
        <Header />
        <div className="p-4 flex flex-col flex-grow">
          <select
            className="mb-4 p-2 border rounded"
            value={selectedOption}
            onChange={(e) => setSelectedOption(e.target.value)}
          >
            <option value="Voltaje">Voltaje</option>
            <option value="Temperatura y HR">Temperatura y HR</option>
            <option value="Humedad del suelo">Humedad del suelo</option>
            <option value="Temperatura del suelo">Temperatura del suelo</option>
            <option value="Todo">Todo</option>
          </select>
          <div className="flex-grow">
            <Chart
              options={options}
              series={series}
              type="line"
              height="100%"
              width="100%"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Graficos;
