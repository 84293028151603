// src/components/Sidebar.js
import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import logoSmall from "../images/logo-small.png";

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    // Eliminar el token de localStorage
    localStorage.removeItem("authToken");
    // Redirigir a la página de inicio de sesión
    navigate("/login");
  };

  return (
    <>
      {/* Botón para abrir/cerrar el menú en dispositivos móviles */}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="md:hidden bg-gray-800 text-white p-2 rounded-full focus:outline-none fixed top-4 left-4 z-50"
      >
        {isOpen ? "✕" : "☰"}
      </button>

      {/* Sidebar */}
      <div
        className={`fixed inset-y-0 left-0 bg-gray-600 text-white w-64 p-4 transition-transform transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } md:translate-x-0 md:relative md:translate-x-0 z-40`}
      >
        <nav className="mt-10">
          <div className="text-white">
            <img src={logoSmall} alt="Logo" />
          </div>
          <Link
            to="/dashboard"
            className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 hover:text-white"
          >
            Dashboard
          </Link>
          <Link
            to="/datos"
            className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 hover:text-white"
          >
            Datos
          </Link>
          <Link
            to="/graficos"
            className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 hover:text-white"
          >
            Graficos
          </Link>
          <Link
            to="/perfil"
            className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 hover:text-white"
          >
            Perfil
          </Link>
          <Link
            to="/ajustes"
            className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 hover:text-white"
          >
            Ajustes
          </Link>
          <button
            onClick={handleLogout}
            className="block w-full text-left py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 hover:text-white"
          >
            Cerrar sesión
          </button>
        </nav>
      </div>

      {/* Overlay para cerrar el menú en dispositivos móviles */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 md:hidden z-30"
          onClick={() => setIsOpen(false)}
        ></div>
      )}
    </>
  );
};

export default Sidebar;
