// src/components/Login.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../images/logo.png";
import background from "../images/background-image.jpg"; // Una imagen de fondo que puedas usar

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(""); // Reset error message

    try {
      const response = await fetch(process.env.REACT_APP_BACKEND + "login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });

      if (response.ok) {
        const data = await response.json();
        const token = data.token;
        // Guardar el token en localStorage
        localStorage.setItem("authToken", token);
        // Redirigir a /dashboard
        navigate("/dashboard");
      } else {
        // Manejar errores de autenticación
        const errorData = await response.json();
        setError("Credenciales inválidas" || "Error al iniciar sesión");
      }
    } catch (err) {
      // Manejar errores de red
      setError("No se pudo conectar con el servidor");
    }
  };

  return (
    <div className="flex min-h-screen">
      {/* Panel izquierdo (Formulario de Login) */}
      <div className="flex flex-col items-center justify-center w-full lg:w-1/2 p-8 bg-gray-100">
        {/* Logo sobre el formulario */}
        <div className="mb-6">
          <img src={logo} alt="Logo" className="h-10" />
        </div>
        {/* Formulario de Login */}
        <div className="bg-white p-8 rounded shadow-md w-full max-w-sm">
          <h1 className="text-2xl font-bold mb-6 text-center">
            Inicio de sesión
          </h1>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-gray-700">Nombre de usuario</label>
              <input
                type="text"
                className="w-full px-3 py-2 border rounded"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Contraseña</label>
              <input
                type="password"
                className="w-full px-3 py-2 border rounded"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            {error && <p className="text-red-500 mb-4">{error}</p>}
            <div className="flex items-center justify-between">
              <label className="flex items-center">
                <input type="checkbox" className="mr-2" />
                <span className="text-sm text-gray-600">Recordar</span>
              </label>
              <button
                type="submit"
                className="bg-red-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Entrar
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* Panel derecho (Imagen de fondo) */}
      <div
        className="hidden lg:flex w-1/2 bg-cover bg-center"
        style={{ backgroundImage: `url(${background})` }}
      ></div>
    </div>
  );
};

export default Login;
