import React, { useState, useEffect } from "react";
import { Menu } from "@headlessui/react";
import axios from "axios";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css";
import { ArrowUpIcon, ArrowDownIcon } from "@heroicons/react/solid";

const Datos = () => {
  const [variables, setVariables] = useState([]);
  const [stations, setStations] = useState([]);
  const [selectedVariable, setSelectedVariable] = useState("");
  const [selectedStation, setSelectedStation] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showLastYearData, setShowLastYearData] = useState(false); // State for checkbox
  const apiUrl = process.env.REACT_APP_BACKEND;

  useEffect(() => {
    const token = localStorage.getItem("authToken");

    // Fetching variables
    axios
      .get(`${apiUrl}/parser/variables`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const variablesArray = response.data.map((item) => {
          const [key, value] = Object.entries(item)[0];
          return { key, value };
        });
        setVariables(variablesArray);
      })
      .catch((error) => console.error("Error fetching variables:", error));

    // Fetching stations
    axios
      .get(`${apiUrl}/parser/stations`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const stationsArray = response.data.map((item) => {
          const [key, value] = Object.entries(item)[0];
          return { key, value };
        });
        setStations(stationsArray);
      })
      .catch((error) => console.error("Error fetching stations:", error));
  }, []);

  const fetchData = (stationKey, variableKey, page, pastYear = false) => {
    console.log("fetch", pastYear);
    if (stationKey && variableKey) {
      const token = localStorage.getItem("authToken");
      setLoading(true);

      // Fixed dates for both desde and hasta
      const desde = "2024-08-25";
      const hasta = "2024-08-25";
      //const pastYear = showLastYearData; // Use the toggle state

      axios
        .get(
          `${apiUrl}/parser/station/${stationKey}/variable/${variableKey}?page=${page}&desde=${desde}&hasta=${hasta}&pastYear=${pastYear}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          const { documents, total_pages } = response.data;
          if (documents && documents.length > 0) {
            setData(documents);
            setTotalPages(total_pages);
          } else {
            setData([]);
            setShowModal(true);
          }
        })
        .catch((error) =>
          console.error(
            "Error fetching data for selected station and variable:",
            error
          )
        )
        .finally(() => setLoading(false));
    } else {
      setData([]);
    }
  };

  const handleVariableChange = (key) => {
    setSelectedVariable(key);
    fetchData(selectedStation, key, page);
  };

  const handleStationChange = (key) => {
    setSelectedStation(key);
    fetchData(key, selectedVariable, page);
  };

  const handleToggleChange = () => {
    // Usamos la función de actualización de estado con el valor anterior
    setShowLastYearData((prevState) => {
      const newValue = !prevState; // Invertimos el estado actual
      console.log(newValue);
      // Ejecutamos fetchData con el nuevo valor
      fetchData(selectedStation, selectedVariable, page, newValue);
      return newValue; // Actualizamos el estado con el nuevo valor
    });
  };

  // Función para formatear los valores de la columna 'Valor' dependiendo del estado del toggle
  const formatValue = (document) => {
    if (showLastYearData && document.lastYearValue) {
      const currentValue = document.Valor;
      const lastYearValue = document.lastYearValue.Valor;

      // Mostrar flecha de comparación junto a los valores
      const comparisonIcon =
        currentValue > lastYearValue ? (
          <ArrowUpIcon className="w-5 h-5 text-red-500 inline ml-2" />
        ) : (
          <ArrowDownIcon className="w-5 h-5 text-green-500 inline ml-2" />
        );

      return (
        <>
          {`${currentValue} / ${lastYearValue}`}
          {comparisonIcon}
        </>
      );
    } else {
      // Mostrar solo el valor original si el toggle está apagado
      return document.Valor;
    }
  };

  const today = format(new Date(), "iiii d 'de' MMMM 'de' yyyy", {
    locale: es,
  });

  const averageValue =
    data.length > 0
      ? (
          data.reduce((sum, item) => sum + (parseFloat(item.Valor) || 0), 0) /
          data.length
        ).toFixed(2)
      : 0;

  return (
    <div className="flex h-screen overflow-hidden bg-gray-100">
      <Sidebar />
      <div className="flex-1 flex flex-col">
        <Header />
        <main className="flex-1 overflow-y-auto p-4 bg-white">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold">Tabla de Datos</h2>
            <div className="text-gray-600 text-right">
              <p>Fecha última actualización: {today}</p>
            </div>
          </div>

          {loading && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
              <div className="bg-white p-6 rounded shadow-lg flex flex-col items-center">
                <svg
                  className="animate-spin h-8 w-8 text-gray-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v8z"
                  ></path>
                </svg>
                <p className="mt-4 text-sm text-gray-600">Cargando...</p>
              </div>
            </div>
          )}

          <div className="flex space-x-4 mb-4">
            <Menu as="div" className="relative w-[250px]">
              <Menu.Button className="inline-flex justify-between items-center w-full px-4 py-2 border border-gray-300 bg-white text-gray-700 rounded-md hover:bg-gray-50">
                {selectedVariable
                  ? variables.find((v) => v.key === selectedVariable)?.value
                  : "Selecciona una variable"}
              </Menu.Button>
              <Menu.Items className="absolute z-10 mt-1 w-full bg-white border border-gray-300 rounded-md shadow-lg">
                {variables.map((variable) => (
                  <Menu.Item key={variable.key}>
                    {({ active }) => (
                      <button
                        className={`block px-4 py-2 text-sm ${
                          active ? "bg-gray-100" : "text-gray-700"
                        }`}
                        onClick={() => handleVariableChange(variable.key)}
                      >
                        {variable.value}
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Menu>

            <Menu as="div" className="relative w-[250px]">
              <Menu.Button className="inline-flex justify-between items-center w-full px-4 py-2 border border-gray-300 bg-white text-gray-700 rounded-md hover:bg-gray-50">
                {selectedStation
                  ? stations.find((s) => s.key === selectedStation)?.value
                  : "Selecciona una estación"}
              </Menu.Button>
              <Menu.Items className="absolute z-10 mt-1 w-full bg-white border border-gray-300 rounded-md shadow-lg">
                {stations.map((station) => (
                  <Menu.Item key={station.key}>
                    {({ active }) => (
                      <button
                        className={`block px-4 py-2 text-sm ${
                          active ? "bg-gray-100" : "text-gray-700"
                        }`}
                        onClick={() => handleStationChange(station.key)}
                      >
                        {station.value}
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Menu>

            <button
              className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600"
              onClick={() => fetchData(selectedStation, selectedVariable, page)}
            >
              Buscar
            </button>
          </div>

          {/* Toggle for showing last year data */}
          <div className="flex items-center mb-4">
            <label
              htmlFor="showLastYearData"
              className="flex items-center cursor-pointer"
            >
              <div className="relative">
                <input
                  type="checkbox"
                  id="showLastYearData"
                  checked={showLastYearData}
                  onChange={handleToggleChange} // Use the toggle change handler
                  className="sr-only"
                />
                <div className="block bg-gray-400 w-14 h-8 rounded-full"></div>
                <div
                  className={`dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition ${
                    showLastYearData ? "translate-x-full bg-green-400" : ""
                  }`}
                ></div>
              </div>
              <span className="ml-3 text-gray-700">
                {showLastYearData
                  ? "Mostrar datos de año anterior"
                  : "No mostrar datos de año anterior"}
              </span>
            </label>
          </div>

          {/* Statbar with black background */}
          <div className="flex justify-between bg-black p-4 rounded-md text-white">
            <div>
              <p className="text-gray-400 text-xs">Estación Seleccionada:</p>
              <p className="text-lg font-semibold">
                {selectedStation
                  ? stations.find((s) => s.key === selectedStation)?.value
                  : "-"}
              </p>
            </div>
            <div>
              <p className="text-gray-400 text-xs">Variable Seleccionada:</p>
              <p className="text-lg font-semibold">
                {selectedVariable
                  ? variables.find((v) => v.key === selectedVariable)?.value
                  : "-"}
              </p>
            </div>
            <div>
              <p className="text-gray-400 text-xs">Promedio de Valor:</p>
              <p className="text-lg font-semibold">{averageValue}</p>
            </div>
            <div>
              <p className="text-gray-400 text-xs">Total de Registros:</p>
              <p className="text-lg font-semibold">{data.length}</p>
            </div>
          </div>

          {data.length > 0 ? (
            <div className="overflow-x-auto mt-4">
              <table className="min-w-full border border-gray-300">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="border border-gray-300 px-4 py-2">
                      Estación
                    </th>
                    <th className="border border-gray-300 px-4 py-2">Fecha</th>
                    <th className="border border-gray-300 px-4 py-2">
                      Porcentaje de Datos
                    </th>
                    <th className="border border-gray-300 px-4 py-2">Valor</th>
                    <th className="border border-gray-300 px-4 py-2">
                      Variable
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item) => (
                    <tr key={item._id}>
                      <td className="border border-gray-300 px-4 py-2">
                        {item["Estación"]}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {format(new Date(item.Fecha), "dd/MM/yyyy")}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {item["Porcentaje de Datos"]}%
                      </td>
                      {/* <td className="border border-gray-300 px-4 py-2">
                        {item.Valor}
                      </td> */}
                      <td className="border border-gray-300 px-4 py-2">
                        {formatValue(item)}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {item.Variable}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="flex justify-between mt-4">
                <button
                  onClick={() => {
                    if (page > 1) setPage(page - 1);
                  }}
                  disabled={page <= 1}
                  className="px-4 py-2 bg-gray-300 rounded-md hover:bg-gray-400"
                >
                  Anterior
                </button>
                <span className="self-center text-lg">
                  Página {page} de {totalPages}
                </span>
                <button
                  onClick={() => {
                    if (page < totalPages) setPage(page + 1);
                  }}
                  disabled={page >= totalPages}
                  className="px-4 py-2 bg-gray-300 rounded-md hover:bg-gray-400"
                >
                  Siguiente
                </button>
              </div>
            </div>
          ) : (
            <div className="mt-4 text-center text-gray-600">
              <p>No hay datos disponibles.</p>
            </div>
          )}

          {showModal && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
              <div className="bg-white p-6 rounded shadow-lg flex flex-col items-center">
                <h3 className="text-lg font-semibold">Sin Datos</h3>
                <p className="mt-2 text-sm text-gray-600">
                  No hay datos disponibles para esta combinación de estación y
                  variable.
                </p>
                <button
                  className="mt-4 px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600"
                  onClick={() => setShowModal(false)}
                >
                  Cerrar
                </button>
              </div>
            </div>
          )}
        </main>
      </div>
    </div>
  );
};

export default Datos;
