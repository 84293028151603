// src/App.js
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import Datos from "./components/Datos";
import PrivateRoute from "./components/PrivateRoute";
import Graficos from "./components/Graficos";

const App = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      >
        {/* Ruta hija que se renderizará dentro del Outlet en Dashboard */}
      </Route>
      <Route path="datos" element={<Datos />} />
      <Route path="graficos" element={<Graficos />} />
      {/* Redirigir a login si ninguna ruta coincide */}
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
